import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import * as CONFIG from '../../constants/config';
import * as MESSAGES from '../../constants/messages';
import { Alert, Card, Col, Form, Row } from 'react-bootstrap';
import { PiWarningDiamondFill } from 'react-icons/pi';

const SignUpPage = () => (
  <div className='container' style={{ padding: '40px 0' }}>
    <h2 className='my-4'>Crear nou usuari <small>Si tens qualsevol incidència contacta amb <a href='mailto:programacio@fic-cat.cat' target='_blank'>programacio@fic-cat.cat</a></small></h2>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  isAdmin: false,
  error: null,
  typeCopy: ''
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      email: props.match.params.mail ? props.match.params.mail : '',
      passwordOne: '',
      passwordTwo: '',
      role: props.match.params.role ? props.match.params.role : '',
      validated: false,
      error: null,
      inputRoleName: false
    };

    switch ( props.match.params.role ) {
      case "oficial":
        this.state.inputRoleName = 'JURAT';
        this.state.allowed = CONFIG.JURY_OFICIAL.indexOf(this.state.email) !== -1;
        this.state.typeCopy = 'Jurat selecció Oficial'
      break;
      case "jove":
        this.state.inputRoleName = 'JURAT';
        this.state.allowed = CONFIG.JURY_YOUNG.indexOf(this.state.email) !== -1;
        this.state.typeCopy = 'Jurat Concurs Jove'
      break;
      case "centres":
        this.state.inputRoleName = 'JURAT';
        this.state.allowed = CONFIG.JURY_SCHOOL.indexOf(this.state.email) !== -1;
        this.state.typeCopy = 'Jurat Centres Educatius'
      break;
      case "editor":
        this.state.inputRoleName = 'EDITOR';
        this.state.allowed = CONFIG.EDITOR.indexOf(this.state.email) !== -1;
        this.state.typeCopy = 'Editor'
      break;
      case "proveidor":
        this.state.inputRoleName = 'PROVIDER';
        this.state.allowed = CONFIG.PROVIDER.indexOf(this.state.email) !== -1;
        this.state.typeCopy = 'Proveïdor de serveis'
        this.state.role = '3CAT'
      break;
      
    }


  }

  sendForm(e) {
   
    e.preventDefault();

    const form = e.currentTarget;
    this.setState({ validated: true });
    const roles = {};

    if (this.state.inputRoleName && form.elements[this.state.inputRoleName]) {
      roles[this.state.inputRoleName] = form.elements[this.state.inputRoleName].value;
    }
    

    if (form.checkValidity() === false) {
      U.scrollToFirstError(e);
    } else {

      const username = form.elements['username'].value;
      const email = form.elements['email'].value;
      const passwordOne = form.elements['passwordOne'].value;

      this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set({
          username,
          email,
          roles,
        });
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });
    }
  }

  onChange = event => {

    if (event.target.name && event.target.value) {
      this.setState({ [event.target.name]: event.target.value });
    }

  };

  onChangeCheckbox = event => {
    // this.setState({ [event.target.name]: event.target.checked });
  };


  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
      allowed,
      typeCopy,
      role,
      inputRoleName
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';

    return (
      <>
        { allowed ? (
          <Form noValidate validated={ this.state.validated } id="registerForm" onSubmit={this.sendForm.bind(this)} ref='registerForm'>
        <Card bg="light">
          <Card.Body>
            <Row>
              <Col xs={12}>
                <h5 className='text-muted mb-4'>{typeCopy}:</h5>
              </Col>
            <Col xs={12}>
                <Form.Group  className="mb-3" controlId="email">
                  <Form.Label>E-mail:</Form.Label>
                  <Form.Control
                      required
                      type='text'
                      disabled
                      defaultValue={email}
                      placeholder='Ex: joanroig@mail.cat'
                    />
                    <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                  </Form.Group>  
              </Col>
              <Col xs={12}>
                <Form.Group  className="mb-3" controlId="username">
                  <Form.Label>Nom i cognoms:</Form.Label>
                  <Form.Control
                      required
                      type='text'
                      defaultValue={username}
                      placeholder='Ex: Joan Roig'
                    />
                    <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                  </Form.Group>  
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group  className="mb-3" controlId="passwordOne">
                  <Form.Label>Contrasenya:</Form.Label>
                  <Form.Control
                      required
                      type='passsword'
                      name='passwordOne'
                      onChange={this.onChange}
                      defaultValue={passwordOne}
                      placeholder='Ex: 123456'
                    />
                    <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                  </Form.Group>  
              </Col>
              <Col xs={12}  sm={6}>
                <Form.Group className="mb-3" controlId="passwordTwo">
                  <Form.Label>Confirmar contrassenya:</Form.Label>
                  <Form.Control
                      required
                      type='passsword'
                      name='passwordTwo'
                      defaultValue={passwordTwo}
                      onChange={this.onChange}
                      placeholder='Ex: 123456'
                      isInvalid={
                        (this.state.passwordOne !== this.state.passwordTwo)
                      }
                    />
                    <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_PASSWORD }</Form.Control.Feedback>
                  </Form.Group>  
              </Col>
              <Col>
              <button type="submit" className="btn btn-lg btn-success">
                Registrar-me
              </button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        
        {inputRoleName && (
          <input
            name={inputRoleName}
            type="hidden"
            value={ role.toUpperCase() }
            onChange={this.onChangeCheckbox}
          />
        )}

        {error && <p>{error.message}</p>}
        </Form>
        ) : (
          <Alert variant="warning"><PiWarningDiamondFill /> <strong>Aquest e-mail no esta autoritzat</strong> per registrar-se com a {typeCopy}.</Alert>
        )}
      </>
      
    );
  }
}

const SignUpLink = () => (
  <p>
    {/*
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
    */}
  </p>

);

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export default SignUpPage;

export { SignUpForm, SignUpLink };

import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from "../../constants/utilities";


import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { PiWarningDiamondFill } from 'react-icons/pi';

class FilmsFormCast extends Component {

  constructor(props) {
    super(props);

    if (props.film) {
      const film = props.film;
      this.state = {
        director: film.filmDirector ? film.filmDirector : '',
        schoolTutor: film.filmSchoolTutor ? film.filmSchoolTutor : '',
        directorGenre: film.filmDirectorGenre ? film.filmDirectorGenre : '',
        producerName: film.producerName ? film.producerName : '',
        producerGenre: film.producerGenre ? film.producerGenre : '',
        producerCompany: film.producerCompany ? film.producerCompany : '',
        producerSite: film.producerSite ? film.producerSite : '',
        writerName: film.writerName ? film.writerName : '',
        writerGenre: film.writerGenre ? film.writerGenre : '',
        filmActoring: film.filmActoring ? film.filmActoring : '',
      };
    } else {
      this.state = {
        name: props.name ? props.name: '',
        director: props.director ? props.director : '',
        schoolTutor: props.schoolTutor ? props.schoolTutor : '',
        directorGenre: props.directorGenre ? props.directorGenre : '',
        producerName: props.producerName ? props.producerName : '',
        producerGenre: props.producerGenre ? props.producerGenre : '',
        producerCompany: props.producerCompany ? props.producerCompany : '',
        producerSite: props.producerSite ? props.producerSite : '',
        writerName: props.writerName ? props.writerName : '',
        writerGenre: props.writerGenre ? props.writerGenre : '',
        filmActoring: props.filmActoring ? props.filmActoring : '',
      };
    }

    // Set default state
    this.state.type =  props.type;
    this.state.formStructure = props.formStructure ? props.formStructure : [];

  }
  render() {
    const { formStructure, director, schoolTutor, directorGenre, producerName, producerGenre, producerCompany, producerSite, writerName, writerGenre, filmActoring } = this.state;
    return (
      <div className='pb-2'>
        { formStructure.indexOf('filmDirector') !== -1 && (
          <>
          <h2 className='mt-4 pb-2 mb-4'>Fitxa tècnica i artística <small>Equip tècnic i artístic de la producció.</small></h2>
          <Card bg="light" className='p-3 pb-2 mb-4'>
          <Row>
            <h4 className='text-muted'>Direcció</h4>
            { formStructure.indexOf('filmSchoolTutor') !== -1 && (
            <>
              <Col>
                <Form.Group  className="mb-3" controlId="filmSchoolTutor">
                  <Form.Label>Nom del tutor/a</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    name="filmSchoolTutor"
                    defaultValue={schoolTutor}
                    placeholder='Ex: Carol Reig'
                  />
                  <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm>
                <Form.Group  className="mb-3" controlId="filmDirector">
                <Form.Label>Nom dels alumnes a carrec de la direcció <small className='text-muted'>(separats per coma)</small></Form.Label>
                <Form.Control
                  required
                  type='text'
                  name="filmDirector"
                  defaultValue={director}
                  placeholder='Ex: Joana Roig'
                />
                <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
              </Form.Group>
              </Col>
            </>
            )}
          { formStructure.indexOf('filmDirector') !== -1 && formStructure.indexOf('filmSchoolTutor') === -1  && (
            <Col sm>
              <Form.Group  className="mb-3" controlId="filmDirector">
              <Form.Label>Nom/s <small className='text-muted'>(Sí són més d'un: separats per coma)</small></Form.Label>
              <Form.Control
                required={this.state.type !== 'PITCHS' ? true : false }
                type='text'
                name="filmDirector"
                defaultValue={director}
                placeholder='Ex: Joana Roig, Martí Ros'
              />
              <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
            </Form.Group>
            </Col>
          )}
          { formStructure.indexOf('filmDirectorGenre') !== -1 && (
          <Col sm>
            <Form.Group  className="mb-3" controlId="filmDirectorGenre">
              <Form.Label>Identitat de Gènere <small className='text-muted'>(Director/a principal)</small></Form.Label>
              <Form.Control as="select"
                required={this.state.type !== 'PITCHS' ? true : false }
                name="filmDirectorGenre"
                defaultValue={directorGenre}
              >
                <option value=''>Selecciona una opció...</option>
                <option value='Dona'>Dona</option>
                <option value='Home'>Home</option>
                <option value='No Binari'>No Binari</option>
                <option value='Declino contestar'>Declino contestar</option>
                </Form.Control>
              <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_UNSELECTED }</Form.Control.Feedback>
            </Form.Group>
          </Col>
          )}
          </Row>
          </Card>
          </>
        )}

          { (formStructure.indexOf('producerName') !== -1 || formStructure.indexOf('producerGenre') !== -1 || formStructure.indexOf('producerCompany') !== -1 || formStructure.indexOf('producerSite') !== -1) && (
          <Card  bg="light" className='p-3 pb-2 mb-4'>
            <h4 className='text-muted'>Producció</h4>
            { (formStructure.indexOf('producerName') !== -1 || formStructure.indexOf('producerGenre') !== -1) && (
            <Row>
              { formStructure.indexOf('producerName') !== -1  && (
                <Col sm>
                  <Form.Group  className="mb-3" controlId="producerName">
                    <Form.Label>Nom/s i cognoms <small className='text-muted'>(Sí són més d'un: separats per coma)</small></Form.Label>
                    <Form.Control
                      type='text'
                      required={this.state.type !== 'PITCHS' ? true : false }
                      name="producerName"
                      defaultValue={producerName}
                      placeholder='Ex: Lucia Vilalta, Luis Perez'
                    />
                    <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              )}
              { formStructure.indexOf('producerGenre') !== -1 && (
              <Col sm>
                <Form.Group  className="mb-3" controlId="producerGenre">
                  <Form.Label>Identitat de Gènere <small className='text-muted'>(Productor/a principal)</small></Form.Label>
                  <Form.Control as="select"
                    required={this.state.type !== 'PITCHS' ? true : false }
                    name="producerGenre"
                    defaultValue={producerGenre}
                  >
                    <option value=''>Selecciona una opció...</option>
                    <option value='Dona'>Dona</option>
                    <option value='Home'>Home</option>
                    <option value='No Binari'>No Binari</option>
                    <option value='Declino contestar'>Declino contestar</option>
                    </Form.Control>
                  <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_UNSELECTED }</Form.Control.Feedback>
                </Form.Group>
              </Col>
              )}
            </Row>
            )}
            { (formStructure.indexOf('producerCompany') !== -1 || formStructure.indexOf('producerSite') !== -1) && (
            <Row>
            { formStructure.indexOf('producerCompany') !== -1 && (
              <Col sm>
                    <Form.Group  className="mb-3" controlId="producerCompany">
                  <Form.Label>Empreses productores <small className='text-muted'>(separades per coma)</small></Form.Label>
                  <Form.Control
                    required={this.state.type !== 'PITCHS' ? true : false }
                    type='text'
                    name="producerCompany"
                    defaultValue={producerCompany}
                    placeholder='Ex: Produccions exemplars, ABC, DEF'
                  />
                  <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                </Form.Group>
              </Col>
          )}
          { formStructure.indexOf('producerSite') !== -1 && (
            <Col sm>
              <Form.Group  className="mb-3" controlId="producerSite">
                <Form.Label>Web de la productora principal</Form.Label>
                <Form.Control
                  required
                  type='text'
                  name="producerSite"
                  defaultValue={producerSite}
                  placeholder='Ex: produccionsexemplars.cat'
                />
                <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
              </Form.Group>
            </Col>
          )}
            </Row>
            )}
          </Card>
          )}
          { (formStructure.indexOf('writerName') !== -1 || formStructure.indexOf('writerGenre') !== -1 )  && (
          <Card bg="light" className='p-3 pb-2 mb-4'>
            <h4 className='text-muted'>Guió</h4>
            <Row>
              { formStructure.indexOf('writerName') !== -1  && (
                <Col sm>
                  <Form.Group  className="mb-3" controlId="writerName">
                    <Form.Label>Nom/s i cognoms<small className='text-muted'>(Sí són més d'un: separats per coma)</small></Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Ex: Pedro Martin, Emilia Roca'
                      required={this.state.type !== 'PITCHS' ? true : false }
                      defaultValue={writerName}
                      name="writerName"
                    />
                    <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              )}
              { formStructure.indexOf('writerGenre') !== -1 && (
              <Col sm>
                <Form.Group  className="mb-3" controlId="writerGenre">
                  <Form.Label>Identitat de Gènere <small className='text-muted'>(Guionista principal)</small></Form.Label>
                  <Form.Control as="select"
                    required={this.state.type !== 'PITCHS' ? true : false }
                    name="writerGenre"
                    defaultValue={writerGenre}
                  >
                    <option value=''>Selecciona una opció...</option>
                    <option value='Dona'>Dona</option>
                    <option value='Home'>Home</option>
                    <option value='No Binari'>No Binari</option>
                    <option value='Declino contestar'>Declino contestar</option>
                    </Form.Control>
                  <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_UNSELECTED }</Form.Control.Feedback>
                </Form.Group>
              </Col>
              )}
            </Row>
          </Card>
          )}
          { formStructure.indexOf('filmActoring') !== -1 && (
          <Card bg="light" className='p-3 pb-2 mb-4'>
              <h4 className='text-muted'>Intèrprets principals</h4>
              <Form.Group  className="mb-3" controlId="filmActoring">
              <Form.Label>Noms i cognoms <small className='text-muted'>(fes servir comes per separar els noms)</small></Form.Label>
              <Form.Control
                as='textarea'
                required
                rows="3"
                defaultValue={filmActoring}
                name="filmActoring"
                placeholder='Ex: Júlia Virgili, Pere Verd....'
              >
              </Form.Control>
              <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
            </Form.Group>
            </Card>
            )}
          
      </div>
    )
  }

}



export default compose(
  withFirebase
)(FilmsFormCast);

import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from "../../constants/utilities";


import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import { Card, Col, Container, InputGroup, Row } from 'react-bootstrap';
import { PiBuilding, PiCake, PiCertificate, PiEnvelope, PiIdentificationBadge, PiLink, PiLock, PiPhone, PiSuitcase, PiUser, PiWarningDiamondFill } from 'react-icons/pi';


class FilmsFormContact extends Component {

  constructor(props) {
    super(props);

    const m = props.modifier ? props.modifier : '';

    if (props.film) {
      const film = props.film;
      this.state = {
        contactName:  film['contactName' + m ] ? film['contactName' + m ] : '',
        contactRole: film['contactRole' + m ] ? film['contactRole' + m ] : '',
        contactNIF: film['contactNIF' + m ] ? film['contactNIF' + m ] : '',
        contactBirth: film['contactBirth' + m ] ? film['contactBirth' + m ] : '',
        contactEmail: film['contactEmail' + m ] ? film['contactEmail' + m ] : '',
        contactPhone:film['contactPhone' + m ] ? film['contactPhone' + m ] : '',
        filmSchool: film['filmSchool' + m ] ? film['filmSchool' + m ] : '',
        filmSchoolLevel: film['filmSchoolLevel' + m ] ? film['filmSchoolLevel' + m ] : '',
      };
    } else {
      this.state = {
        contactName: props.contactName ? props.contactName: '',
        contactRole: props.contactRole ? props.contactRole : '',
        contactNIF: props.contactNIF ? props.contactNIF : '',
        contactBirth: props.contactBirth ? props.contactBirth : '',
        contactEmail: props.contactEmail ? props.contactEmail : '',
        contactPhone: props.contactPhone ? props.contactPhone : '',
        filmSchool: props.filmSchool ? props.filmSchool : '',
        filmSchoolLevel: props.filmSchoolLevel ? props.filmSchoolLevel : '',
      };
    }

    // Set default state
    this.state.m = m;
    this.state.type =  props.type;
    this.state.formStructure = props.formStructure ? props.formStructure : [];

  }
  render() {
    const { formStructure, m, contactName, contactRole, contactNIF, contactBirth, contactEmail, contactPhone, filmSchool, filmSchoolLevel } = this.state;
    return (
          <Card bg="light" className='p-3 pb-2 mb-4'>
            <Row>
          { formStructure.indexOf('contactName' + m ) !== -1 && (
            <Col sm={6}>
              <Form.Group controlId={'contactName' + m } className='pb-3'>
                <Form.Label>Nom i cognoms</Form.Label>
                <InputGroup hasValidation>
                <InputGroup.Text><PiUser /></InputGroup.Text>
                <Form.Control
                  required={ m === '' ? true : false}
                  type='text'
                  onChange={ (e) => this.setState({ contactName: e.target.value })}
                  defaultValue={contactName}
                  placeholder='Ex: Joana Roig'
                  name={'contactName' + m }
                />
                <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>

                </InputGroup>
              </Form.Group>
            </Col>
          )}
          { formStructure.indexOf('contactRole' + m) !== -1 && (
            <Col sm={6}>
              <Form.Group controlId={'contactRole' + m} className='pb-3'>
                <Form.Label>Rol en la producció</Form.Label>
                <InputGroup hasValidation>
                <InputGroup.Text><PiSuitcase /></InputGroup.Text>
                <Form.Control
                  required={ m === '' ? true : (contactName === '' ? false : true)}
                  type='text'
                  defaultValue={contactRole}
                  placeholder='Ex: Directora i Productora'
                  name={'contactRole' + m }
                />
                <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>

                </InputGroup>
              </Form.Group>
            </Col>
          )}
          { formStructure.indexOf('contactNIF' + m) !== -1 && (
            <Col sm={6}>
              <Form.Group controlId={'contactNIF' + m} className='pb-3'>
                <Form.Label>DNI / NIE</Form.Label>
                <InputGroup hasValidation>
                <InputGroup.Text><PiIdentificationBadge /></InputGroup.Text>
                <Form.Control
                  required={ m === '' ? true : (contactName === '' ? false : true)}
                  type='text'
                  defaultValue={contactNIF}
                  placeholder='Ex: 12345678A'
                  name={'contactNIF' + m }
                />
                <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>

                </InputGroup>
              </Form.Group>
            </Col>
          )}
          { formStructure.indexOf('contactBirth' + m) !== -1 && (
            <Col sm={6}>
              <Form.Group controlId={'contactBirth' + m} className='pb-3'>
                <Form.Label>Data de naixement</Form.Label>
                <InputGroup hasValidation>
                <InputGroup.Text><PiCake /></InputGroup.Text>
                <Form.Control
                  required={ m === '' ? true : (contactName === '' ? false : true)}
                  type='date'
                  defaultValue={contactBirth}
                  name={'contactBirth' + m }
                  placeholder='Ex: 12345678A'
                />
                <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          )}
          { formStructure.indexOf('contactEmail' + m) !== -1 && (
            <Col sm={6}>
              <Form.Group controlId={'contactEmail' + m} className='pb-3'>
                <Form.Label>Correu electrònic</Form.Label>
                <InputGroup hasValidation>
                <InputGroup.Text><PiEnvelope /></InputGroup.Text>
                <Form.Control
                  required={ m === '' ? true : (contactName === '' ? false : true)}
                  type='email'
                  defaultValue={contactEmail}
                  name={'contactEmail' + m }
                  placeholder='Ex: jroig@exemple.com'
                />
                <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          )}
          { formStructure.indexOf('contactPhone' + m) !== -1 && (
            <Col sm={6}>
              <Form.Group controlId={'contactPhone' + m} className='pb-3'>
              <Form.Label>Número de mòbil</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text><PiPhone /></InputGroup.Text>
                <Form.Control
                  required={ m === '' ? true : (contactName === '' ? false : true)}
                  type='number'
                  defaultValue={contactPhone}
                  placeholder='555 55 55 55'
                  name={'contactPhone' + m }
                />
                <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          )}
          { formStructure.indexOf('filmSchool' + m) !== -1 && (
            <Col sm={6}>
              <Form.Group controlId={'filmSchool' + m}  className="mb-3">
              <Form.Label>Nom del centre educatiu</Form.Label>
              <InputGroup hasValidation>
              <InputGroup.Text><PiBuilding /></InputGroup.Text>
              <Form.Control
                required={ m === '' ? true : (contactName === '' ? false : true)}
                type='text'
                defaultValue={filmSchool}
                placeholder='Ex: Escola Exemple'
                name={'filmSchool' + m }
              />
              </InputGroup>
              <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
            </Form.Group>
            </Col>
          )}
          { formStructure.indexOf('filmSchoolLevel' + m) !== -1 && (               
            <Col sm={6}>
              <Form.Group controlId={'filmSchoolLevel' + m} className="mb-3">
                <Form.Label>Nivell educatiu dels autors de l'obra</Form.Label>
                <InputGroup hasValidation>
                <InputGroup.Text><PiCertificate /></InputGroup.Text>
                <Form.Control as="select"
                  required={ m === '' ? true : (contactName === '' ? false : true)}
                  defaultValue={filmSchoolLevel}
                  name={'filmSchoolLevel' + m }
                >
                  <option value=''>Selecciona una opció...</option>
                  <option value='infantil'>Educació infantil</option>
                  <option value='primaria'>Educació primària</option>
                  <option value='secundaria'>Educació secundària obligatòria</option>
                  <option value='batxillerat'>Batxillerat i cicles formatius</option>
                </Form.Control>
                </InputGroup>
                <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_UNSELECTED }</Form.Control.Feedback>
              </Form.Group>
            </Col>
          )}
            </Row>

          </Card>
    )
  }

}



export default compose(
  withFirebase
)(FilmsFormContact);

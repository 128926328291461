import React, { Component } from "react";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { withAuthorization, withEmailVerification } from "../Session";
import _ from "lodash";

import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


import * as CONFIG from "../../constants/config";
import * as ROUTES from "../../constants/routes";
import * as MESSAGES from "../../constants/messages";
import * as U from '../../constants/utilities';


import Loading from "../Loading";
import { Card, CardFooter, Container } from "react-bootstrap";
import { PiWarningDiamondFill } from "react-icons/pi";
import FilmsListItem from "../FilmsListItem";
import { FaLocationDot } from "react-icons/fa6";
import { FaClock, FaFilm, FaPlusCircle, FaUser } from "react-icons/fa";

let filmType = "";
let view = "";

let edition = '';

if (localStorage.getItem('currentEdition') === null ) {
  edition = CONFIG.CURRENT_EDITION;
} else {
  edition = localStorage.getItem('currentEdition');
} 


let orderSession = (sessionGroup) => {
  return _.sortBy(sessionGroup, "time");
};

class SessionListPage extends Component {
  constructor(props) {
    super(props);

    filmType = props.match.params.type ? props.match.params.type : "llargs";
    view =
      props.match.params.view && U.isUserAdmin()
        ? props.match.params.view
        : "normal";

    this.state = {
      type: null,
      loading: true
    };
  }
  render() {
    return <Sessions />;
  }
}

const DatetoHuman = (date) => {
  let newDate = new Date(date);
  return newDate.toLocaleDateString('ca-CA', {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

// <sessionListItem key={film.uid} film={film} filmType={filmType} />
const SessionList = ({ sessions }) => (
  <div
    className="calendar-list mx-auto"
    xs={1}
    md={view === "normal" ? 3 : 1}
    lg={view === "normal" ? 4 : 1}
  >
    {sessions.map((sessionGroup, keyGroup) => (
        <div className="mb-4" key={keyGroup}>
          <hr className="m-0"/>
        <div key={ 'group-' + keyGroup} className="bg-secondary px-4 py-2">
      <Row key={keyGroup}>
        <h2 className="mt-2 mb-3 ml-3">{DatetoHuman(sessionGroup[0].date)}</h2>
        {orderSession(sessionGroup).map((session, key) => (
          <Col xs={12} sm={6} lg={3} key={key} >
            <Card className="mb-4">
              <Card.Body>
                <Card.Link href={ROUTES.SESSION_EDIT +'/'+ session.uid}>
                <small className="text-muted" style={{fontSize: '.7rem'}}>{session.type.toUpperCase()}</small>
                <h5 className="mb-0 text-truncate"><strong className="text-dark">{session.time}h</strong> <small><strong>{session.name}</strong></small></h5>
                <hr className="m-0 mb-2" />
                <p className="text-dark mb-2"><FaLocationDot className="text-muted" /> {session.location ? session.location.split('-')[1] : ''}</p>
                <p className="text-muted mb-2"><small><FaClock className="text-muted" /> {session.duration ? session.duration : 0} min &nbsp;&nbsp;&nbsp;<FaFilm /> {session.films ? (Object.keys(session.films).length === 1 ? Object.keys(session.films).length  + ' producció' : Object.keys(session.films).length + ' produccions' ) : '0 produccions'}</small></p>
                {session.logistic && (
                  <small className="text-muted">
                    <FaUser /> { CONFIG.LOGISTIC[session.logistic.split("-")[0]].name } 
                  </small>
                )}
                </Card.Link>
              </Card.Body>
              { session && session.films && (
              <CardFooter className="film-slider">
                <Row className="film-list small">
              { session.films.map((film,filmKey) =>(
                      <div className="--item" key={'film-' + filmKey} xs={6} style={{ padding: '0 3px'}}>
                        <FilmsListItem key={film.uid} film={film} filmType={ROUTES['ITEM_' + film.type]} variant="small" />
                      </div>
                      ))}
                      </Row>
              </CardFooter>
              )}
            </Card>
          </Col>
        ))}
      </Row>
      </div>
        <hr className="m-0"/>
</div>
    ))}
    
  </div>
);

/**
 *
 *
 *
 */
class SessionsBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Sessions: [],
      API: this.props.firebase.sessions(),
    };
  }

  getSessionsByIsSelectable(Sessions, value) {
    let SessionsSelectable = [];
    if (Sessions !== null) {
      for (var k = 0; k < Sessions.length; k++) {
        if (Sessions[k].isSelectable === value) {
          SessionsSelectable.push(Sessions[k]);
        }
      }
    }
    return SessionsSelectable;
  }

  timestrToSec(timestr) {
    if (timestr && timestr.toString().indexOf(':') > 0) {
        return Math.floor(timestr.split(':')[0]);
    } else if (timestr) {
      return Math.floor(timestr);
    } else {
      return 0;
    }
  }

  getFilmsSelected(categoryBySession, session, sessionGroupKey, sessionKey, groupedSessions) {

    let filmList = []
    let totalDuration = 0;
    let scopeKeeper = this;

    this.props.firebase.database().ref('films/' + edition + '/' + categoryBySession).orderByChild('isSelected').equalTo(true).on('value', snapshotFilm => {

      if (snapshotFilm && snapshotFilm.val()) {
        // GET ORIGINAL DATA

        let snapshotFilmObject = snapshotFilm;

        snapshotFilmObject.forEach(function (childSnapshot, key) {

          let childSnapshotValue = childSnapshot.val();

          childSnapshotValue.uid = childSnapshot.key;
          childSnapshotValue.type = snapshotFilm.key;

          let realLength = childSnapshotValue.filmLengthFixed ? childSnapshotValue.filmLengthFixed : childSnapshotValue.filmLength
          totalDuration += scopeKeeper.timestrToSec(realLength);

          if (categoryBySession === 'CENTRES' ) {
            if (session.type === 'primaria' && childSnapshotValue.filmSchoolLevel === 'primaria') {
              filmList.push( childSnapshotValue )
            } else if (session.type === 'secundaria' && childSnapshotValue.filmSchoolLevel !== 'primaria') {
              filmList.push( childSnapshotValue )
            }
          } else {
            filmList.push( childSnapshotValue )
          }
        });

        groupedSessions[sessionGroupKey][sessionKey].duration = totalDuration;
        groupedSessions[sessionGroupKey][sessionKey].films = _.sortBy( filmList, 'sessionOrder' );

        this.setState({
          groupedSessions: groupedSessions,
          loading: false
        })
        
    }
    
      
      
    })
  }

  UpdatesessionList() {

    let scopeKeeper = this;

    this.setState({
      loading: true
    })
    this.state.API.on("value", (snapshot) => {
      const sessionObject = snapshot.val();
      if (sessionObject) {
        const sessionList = Object.keys(sessionObject).map((key) => ({
          ...sessionObject[key],
          uid: key,
        }));
        // convert messages list from snapshot

        let groupedSessions = _.map(_.groupBy(sessionList, "date"));

        scopeKeeper.setState({
          sessions: groupedSessions,
        });

          groupedSessions.forEach(function (sessionGroup, sessionGroupKey) {
          sessionGroup.forEach(function (session, sessionKey) {

            let categories = [];
            let filmList = [];
            let totalDuration = 0;
            let SessionToGetFilms = (session.session && session.session.uid) ? session.session.uid : session.uid

            switch(session.type) {
              case "videoclips":
               // scopeKeeper.getFilmsSelected('VIDEOCLIPS', session,sessionGroupKey, sessionKey, groupedSessions );

               categories = ['VIDEOCLIPS']
              break;
              case "primaria":
              case "secundaria":
                // scopeKeeper.getFilmsSelected('CENTRES', session);
                categories = ['CENTRES']
              break;
              case "joves":
                // scopeKeeper.getFilmsSelected('JOVES', session);
                categories = ['JOVES']
              break;
              case "standard":
              default:
                categories = ['CURTS','DOCS','LLARGS']            
              break;
            }

            categories.map((category) => {
                  
              scopeKeeper.props.firebase.database().ref('films/' + edition + '/' + category).orderByChild('sessionUid').equalTo(SessionToGetFilms).on('value', snapshotFilm => {
          
                if (snapshotFilm && snapshotFilm.val()) {
                    // GET ORIGINAL DATA

                    snapshotFilm.forEach(function (childSnapshot) {

                      let childSnapshotValue = childSnapshot.val();

                      childSnapshotValue.uid = childSnapshot.key;
                      childSnapshotValue.type = snapshotFilm.key;

                      let realLength = childSnapshotValue.filmLengthFixed ? childSnapshotValue.filmLengthFixed : childSnapshotValue.filmLength
                      totalDuration += scopeKeeper.timestrToSec(realLength);

                      filmList.push( childSnapshotValue )
                    });
                    
                    groupedSessions[sessionGroupKey][sessionKey].duration = totalDuration;
                    groupedSessions[sessionGroupKey][sessionKey].films = _.sortBy( filmList, 'sessionOrder' );
        
                    scopeKeeper.setState({
                      sessions: groupedSessions,
                      loading: false,
                    });
        
                }
              })
            }) 

          })
        })
        
      } else {
        this.setState({ Sessions: null, loading: false });
      }
    });
  }
  componentDidMount() {
    this.UpdatesessionList();
  }

  UNSAFE_componentWillReceiveProps() {
    this.UpdatesessionList();
  }

  componentWillUnmount() {
    if (this.state.API) {
      this.state.API.off();
    }
  }
  render() {
    const { sessions, loading } = this.state;
    return (
      <div>
        <Container>
          <Row className="pb-4">
            <Col sm>
              <h2 className="my-4">Programació <strong className='text-primary'>{edition}</strong><br /><small>Sessions i projeccions</small></h2>
            </Col>
            {U.isUserAdmin() && (
            <Col className="text-lg-end pt-lg-4">
              <a href={ROUTES.FORM_SESSIONS} className="btn btn-success">
                <FaPlusCircle /> Afegir Sessió
              </a>
            </Col>
            )}
          </Row>

          {sessions ? (
            <>
              <SessionList sessions={sessions} />
            </>
          ) : (
            <>
            { loading ? (
              <Loading />
            ) : (
              <Alert variant="warning">
                <PiWarningDiamondFill /> No hi ha cap sessió creada encara.
              </Alert>
            )}
          </>
          )}
        </Container>
      </div>
    );
  }
}
const Sessions = withFirebase(SessionsBase);

const condition = () => { return U.isUserCommittee()};

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(SessionListPage);

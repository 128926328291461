import React from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import SignOutButton from "../SignOut";
import * as ROUTES from "../../constants/routes";
import * as U from "../../constants/utilities";
import "./index.scss";
import { FaUser } from "react-icons/fa";


const Navigation = () =>
  !U.isUserAnonymous() ? (
    <>
      <NavigationAuth authUser={U.authUser()} />
    </>
  ) : (
    <NavigationNonAuth />
  );

const NavigationEdition = () => {

  return (
    <div>
      <Form.Group controlId="filmCategory" className="mx-2 mb-0">
        <Form.Control
          as="select"
          defaultValue={U.activeEdition().toString()}
          onChange={(e) => U.setActiveEdition(e, true)}
        >
          <option value="2020">2020</option>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
        </Form.Control>
      </Form.Group>
    </div>
  );
};

const NavigationAuth = ({ authUser, location = useLocation() }) => (
  <div className="Navigation">
      <Navbar expand="lg">
        <Navbar.Brand href="/llistat">
          <img alt="FIC-CAT" src="/img/logo.png" />
        </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              { (U.isUserCommittee() || U.isUserContentManager()) &&
                <NavigationEdition />
              }
              
              { (!U.showTopFilms() && (U.isUserCommittee() )) ? (
                <Nav
                  variant="pills"
                  className="me-auto"
                >
                  <Nav.Item>
                    <Nav.Link
                      className={` ${
                        location.pathname === ROUTES.LIST_LLARGS ? "active" : ""
                      }`}
                      href={ROUTES.LIST_LLARGS}
                    >
                      Llargmetratges
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className={` ${
                        location.pathname === ROUTES.LIST_DOCS ? "active" : ""
                      }`}
                      href={ROUTES.LIST_DOCS}
                    >
                      Documentals
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className={` ${
                        location.pathname === ROUTES.LIST_CURTS ? "active" : ""
                      }`}
                      href={ROUTES.LIST_CURTS}
                    >
                      Curtmetratges
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className={` ${
                        location.pathname === ROUTES.LIST_VIDEOCLIPS
                          ? "active"
                          : ""
                      }`}
                      href={ROUTES.LIST_VIDEOCLIPS}
                    >
                      Videoclips
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className={` ${
                        location.pathname === ROUTES.LIST_CENTRES
                          ? "active"
                          : ""
                      }`}
                      href={ROUTES.LIST_CENTRES}
                    >
                      Centres
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className={` ${
                        location.pathname === ROUTES.LIST_JOVES ? "active" : ""
                      }`}
                      href={ROUTES.LIST_JOVES}
                    >
                      Concurs Jove
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              ) : (
                <>
                { (  U.isUserFilmaker() && !U.isUserDistributor() && U.getUSerFilms().length > 1) && (
                  <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-filmaker">
                      Produccions seleccionades
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                    { U.getUSerFilms().map((t,k) => (
                      <Dropdown.Item
                        key={'link-to-' + k}
                        className={`${
                          location.pathname === ROUTES.CHOICE_LLARGS
                            ? "active"
                            : ""
                        }`}
                        href={ROUTES.ITEM_STUFF + '/' + t.type + '/' + t.year + '/' + t.uid}
                      >
                        {t.name}
                      </Dropdown.Item>
                    ))}

                      </Dropdown.Menu>
                  </Dropdown>
                )}
                  {(U.isUserCommittee() )? (
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="light"
                        id="dropdown-inscripcions"
                      >
                        Inscripcions
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className={`${
                            location.pathname === ROUTES.LIST_LLARGS
                              ? "active"
                              : ""
                          }`}
                          href={ROUTES.LIST_LLARGS}
                        >
                          Llargmetratges
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={`${
                            location.pathname === ROUTES.LIST_DOCS
                              ? "active"
                              : ""
                          }`}
                          href={ROUTES.LIST_DOCS}
                        >
                          Documentals
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={`${
                            location.pathname === ROUTES.LIST_CURTS
                              ? "active"
                              : ""
                          }`}
                          href={ROUTES.LIST_CURTS}
                        >
                          Curtmetratges
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={`${
                            location.pathname === ROUTES.LIST_VIDEOCLIPS
                              ? "active"
                              : ""
                          }`}
                          href={ROUTES.LIST_VIDEOCLIPS}
                        >
                          Videoclips
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={`${
                            location.pathname === ROUTES.LIST_CENTRES
                              ? "active"
                              : ""
                          }`}
                          href={ROUTES.LIST_CENTRES}
                        >
                          Centres
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={`${
                            location.pathname === ROUTES.LIST_JOVES
                              ? "active"
                              : ""
                          }`}
                          href={ROUTES.LIST_JOVES}
                        >
                          Concurs Jove
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={`${
                            location.pathname === ROUTES.LIST_PITCH
                              ? "active"
                              : ""
                          }`}
                          href={ROUTES.LIST_PITCH}
                        >
                          Pitch de projectes
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                        <Nav
                          variant="pills"
                          className="me-auto"
                        >
                          { (U.isUserJuryOficial() ||  U.isUserProvider()) && (
                            <>
                              <Nav.Item>
                                <Nav.Link
                                  className={` ${
                                    location.pathname === ROUTES.CHOICE_LLARGS
                                      ? "active"
                                      : ""
                                  }`}
                                  href={ROUTES.CHOICE_LLARGS}
                                >
                                  Llargmetratges
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  className={` ${
                                    location.pathname === ROUTES.CHOICE_DOCS
                                      ? "active"
                                      : ""
                                  }`}
                                  href={ROUTES.CHOICE_DOCS}
                                >
                                  Documentals
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  className={` ${
                                    location.pathname === ROUTES.CHOICE_CURTS
                                      ? "active"
                                      : ""
                                  }`}
                                  href={ROUTES.CHOICE_CURTS}
                                >
                                  Curtmetratges
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  className={` ${
                                    location.pathname ===
                                    ROUTES.CHOICE_VIDEOCLIPS
                                      ? "active"
                                      : ""
                                  }`}
                                  href={ROUTES.CHOICE_VIDEOCLIPS}
                                >
                                  Videoclips
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          )}
                          { (U.isUserJurySchool() ||  U.isUserProvider()) && (
                            <Nav.Link
                              className={` ${
                                location.pathname === ROUTES.CHOICE_CENTRES
                                  ? "active"
                                  : ""
                              }`}
                              href={ROUTES.CHOICE_CENTRES}
                            >
                              Centres
                            </Nav.Link>
                          )}
                          { U.isUserJuryYoung() && (
                            <Nav.Link
                              className={` ${
                                location.pathname === ROUTES.CHOICE_JOVES
                                  ? "active"
                                  : ""
                              }`}
                              href={ROUTES.CHOICE_JOVES}
                            >
                              Concurs Jove
                            </Nav.Link>
                          )}
                        </Nav>
                  )}
                </>
              )}
              { U.showTopFilms() && (
                <>
                  <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-inscripcions">
                      Top
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        className={`${
                          location.pathname === ROUTES.TOP_LLARGS
                            ? "active"
                            : ""
                        }`}
                        href={ROUTES.TOP_LLARGS}
                      >
                        Llargmetratges
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={`${
                          location.pathname === ROUTES.TOP_DOCS ? "active" : ""
                        }`}
                        href={ROUTES.TOP_DOCS}
                      >
                        Documentals
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={`${
                          location.pathname === ROUTES.TOP_CURTS ? "active" : ""
                        }`}
                        href={ROUTES.TOP_CURTS}
                      >
                        Curtmetratges
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={`${
                          location.pathname === ROUTES.TOP_VIDEOCLIPS
                            ? "active"
                            : ""
                        }`}
                        href={ROUTES.TOP_VIDEOCLIPS}
                      >
                        Videoclips
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={`${
                          location.pathname === ROUTES.TOP_CENTRES
                            ? "active"
                            : ""
                        }`}
                        href={ROUTES.TOP_CENTRES}
                      >
                        Centres
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={`${
                          location.pathname === ROUTES.TOP_JOVES ? "active" : ""
                        }`}
                        href={ROUTES.TOP_JOVES.replace('comite','jove')}
                      >
                        Concurs Jove
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
              {(U.showTopFilms() && !U.isUserJury()) && (
                <Dropdown>
                  <Dropdown.Toggle variant="light" id="dropdown-inscripcions">
                    Seleccionats
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className={`${
                        location.pathname === ROUTES.CHOICE_LLARGS
                          ? "active"
                          : ""
                      }`}
                      href={ROUTES.CHOICE_LLARGS}
                    >
                      Llargmetratges
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={`${
                        location.pathname === ROUTES.CHOICE_DOCS ? "active" : ""
                      }`}
                      href={ROUTES.CHOICE_DOCS}
                    >
                      Documentals
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={`${
                        location.pathname === ROUTES.CHOICE_CURTS
                          ? "active"
                          : ""
                      }`}
                      href={ROUTES.CHOICE_CURTS}
                    >
                      Curtmetratges
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={`${
                        location.pathname === ROUTES.CHOICE_VIDEOCLIPS
                          ? "active"
                          : ""
                      }`}
                      href={ROUTES.CHOICE_VIDEOCLIPS}
                    >
                      Videoclips
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={`${
                        location.pathname === ROUTES.CHOICE_CENTRES
                          ? "active"
                          : ""
                      }`}
                      href={ROUTES.CHOICE_CENTRES}
                    >
                      Centres educatius
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={`${
                        location.pathname === ROUTES.CHOICE_JOVES
                          ? "active"
                          : ""
                      }`}
                      href={ROUTES.CHOICE_JOVES}
                    >
                      Concurs Jove
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              
              <Nav
                  variant="pills"
                  activeKey={ROUTES.CHOICE_LLARGS}
                  className="me-auto"
                >
              { U.isUserCommittee () && (
                  <Nav.Item>
                    <Nav.Link
                      className={`${
                        location.pathname === ROUTES.SESSION_LIST
                          ? "active"
                          : ""
                      }`}
                      href={ROUTES.SESSION_LIST}
                    >
                      Programació
                    </Nav.Link>
                    
                  </Nav.Item>
              )}
              { U.isUserAdmin() && (
                <Nav.Item>
                <Nav.Link
                        className={`${
                          location.pathname === ROUTES.ADMIN ? "active" : ""
                        }`}
                        href={ROUTES.ADMIN}
                      >
                        Usuaris
                        </Nav.Link>
                    </Nav.Item>
              )}
              </Nav>
            </Navbar.Collapse>
        {!U.isUserFilmaker() ? (
          <Navbar.Collapse className="justify-content-end">
            <Form inline="true" className="mx-2">
              <small><FaUser /> {U.authUser().username}</small>
              &nbsp;<SignOutButton />
            </Form>
          </Navbar.Collapse>
        ) : (
          <div className="justify-content-end px-2">
            <small><FaUser /> {U.authUser().username}</small>
            &nbsp;<SignOutButton />
          </div>
        )}
      </Navbar>
  </div>
);

const NavigationNonAuth = () => (
  <div className="Navigation">
    <Navbar expand="lg">
      <Navbar.Brand href="/">
        <img alt="FIC-CAT" src="/img/logo.png" />
      </Navbar.Brand>
    </Navbar>
  </div>
);

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps)(Navigation);

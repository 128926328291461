export const LANDING = '/';
export const APP_QR = '/app';
export const TV3_QR = '/tv3';

export const SIGN_UP = '/registre';
export const SIGN_IN = '/accedir';
export const SIGN_OUT = '/sortir';
export const SIGN_UP_FILMAKERS = '/accedir-produccio';
export const HOME = '/home';

export const ITEM_EDIT = '/film-edit';
export const ITEM_STUFF = '/material';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';


export const ITEM = '/film';
export const ITEM_CURTS =  'curts';
export const ITEM_DOCS =  'documentals';
export const ITEM_JOVES = 'jove';
export const ITEM_LLARGS = 'llargs';
export const ITEM_CENTRES = 'centres-educatius';
export const ITEM_VIDEOCLIPS = 'videoclips';
export const ITEM_PITCH = 'pitch';

// FORMS
export const FORM_LLARGS = '/llargs';
export const FORM_CURTS = '/curts';
export const FORM_CENTRES = '/centres-educatius';
export const FORM_DOCS = '/documentals';
export const FORM_VIDEOCLIPS = '/videoclips';
export const FORM_YOUNG = '/jove';
export const FORM_PITCHS = '/pitchs';

// USER MANAGEMENT
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';


// SESSIONS
export const SESSION_LIST = '/sessions';
export const SESSION = '/sessio';
export const SESSION_EDIT = '/editar-sessio';
export const FORM_SESSIONS = '/crear-sessio';

// DASHBOARD
export const DASHBOARD = '/llistat';
export const DASHBOARD_LLARGS = DASHBOARD + FORM_LLARGS;
export const DASHBOARD_CURTS = DASHBOARD + FORM_CURTS;
export const DASHBOARD_CENTRES = DASHBOARD + FORM_CENTRES;
export const DASHBOARD_DOCS = DASHBOARD + FORM_DOCS;
export const DASHBOARD_VIDEOCLIPS = DASHBOARD + FORM_VIDEOCLIPS;
export const DASHBOARD_JOVES = DASHBOARD + FORM_YOUNG;

// REGULAR LIST
export const LIST = '/llistat';
export const LIST_URL_FILTER = '/tots/normal';
export const LIST_LLARGS = LIST + FORM_LLARGS + LIST_URL_FILTER;
export const LIST_CURTS = LIST + FORM_CURTS + LIST_URL_FILTER;
export const LIST_CENTRES = LIST + FORM_CENTRES + LIST_URL_FILTER;
export const LIST_DOCS = LIST + FORM_DOCS  + LIST_URL_FILTER;
export const LIST_VIDEOCLIPS = LIST + FORM_VIDEOCLIPS  + LIST_URL_FILTER;
export const LIST_JOVES = LIST + FORM_YOUNG  + LIST_URL_FILTER;
export const LIST_PITCH = LIST + FORM_PITCHS  + LIST_URL_FILTER;

// TOP LIST
export const TOP = '/top';
export const TOP_URL_FILTER = '/tots/comite'
export const TOP_LLARGS = TOP + FORM_LLARGS + TOP_URL_FILTER;
export const TOP_CURTS = TOP + FORM_CURTS + TOP_URL_FILTER;
export const TOP_CENTRES = TOP + FORM_CENTRES + TOP_URL_FILTER;
export const TOP_DOCS = TOP + FORM_DOCS + TOP_URL_FILTER;
export const TOP_VIDEOCLIPS = TOP + FORM_VIDEOCLIPS + TOP_URL_FILTER;
export const TOP_JOVES = TOP + FORM_YOUNG + TOP_URL_FILTER;

// SELECTED LIST
export const CHOICE = '/seleccio';
export const CHOICE_URL_FILTER = '/tots/normal';
export const CHOICE_LLARGS = CHOICE + FORM_LLARGS + CHOICE_URL_FILTER;
export const CHOICE_CURTS = CHOICE + FORM_CURTS + CHOICE_URL_FILTER;
export const CHOICE_CENTRES = CHOICE + FORM_CENTRES + CHOICE_URL_FILTER;
export const CHOICE_DOCS = CHOICE + FORM_DOCS + CHOICE_URL_FILTER;
export const CHOICE_VIDEOCLIPS = CHOICE + FORM_VIDEOCLIPS + CHOICE_URL_FILTER;
export const CHOICE_JOVES = CHOICE + FORM_YOUNG + CHOICE_URL_FILTER;


// SELECTED LIST
export const CHOICE_STUFF = '/seleccio';
export const CHOICE_STUFF_URL_FILTER = '/tots/normal';
export const CHOICE_STUFF_LLARGS = CHOICE + FORM_LLARGS + CHOICE_URL_FILTER;
export const CHOICE_STUFF_CURTS = CHOICE + FORM_CURTS + CHOICE_URL_FILTER;
export const CHOICE_STUFF_CENTRES = CHOICE + FORM_CENTRES + CHOICE_URL_FILTER;
export const CHOICE_STUFF_DOCS = CHOICE + FORM_DOCS + CHOICE_URL_FILTER;
export const CHOICE_STUFF_VIDEOCLIPS = CHOICE + FORM_VIDEOCLIPS + CHOICE_URL_FILTER;
export const CHOICE_STUFF_JOVES = CHOICE + FORM_YOUNG + CHOICE_URL_FILTER;